import { useTranslation } from "react-i18next";
import React, { ChangeEvent, useCallback, useState } from "react";
import Dialog from "components/common/Dialog/Dialog";
import Button from "components/common/Button/Button";
import {
  ContainerButtonStyle,
  DeleteButtonsStyle,
  ImageContainerStyle,
  ImageStyle,
  InputStyle,
} from "./AddExpeditionCategory.style";
import {
  IExpeditionCategoryAddDialog,
  IExpeditionCategoryAddingData,
  IExpeditionCategoryAddingDataError,
} from "./AddExpeditionCategory.types";
import { useSnackBar } from "hooks/useSnackBar";
import TextInput from "components/common/TextInput/TextInput";
import { useAddCategoryMutation } from "redux/api/category/category";
import FileInput from "components/common/FileInput/FileInput";
import { GlobalVariables } from "config/constant";
import { useAddTaxMutation } from "redux/api/tax/taxes";
import { isNaN } from "lodash";
import { useAddExpeditionCategMutation } from "redux/api/delivery/expeditionCategs/expeditionCategs";
import { useNavigate } from "react-router-dom";

function AddExpeditionCategory({ openAddingDialog, setOpenAddingDialog }: IExpeditionCategoryAddDialog) {
  const { t } = useTranslation();

  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();
  const [showModalAddModeExped,setShowModalAddModeExped]=useState(false)
  const [idCateg,setIdCateg]=useState(0)
  const [addingCateg, { data, error, isLoading, reset }] =
    useAddExpeditionCategMutation();

 
  const navigate=useNavigate()
  const closeAddingDialog = useCallback(() => {
    setOpenAddingDialog(false);
    setAddingError({ name: "" })
  }, [setOpenAddingDialog]);
  const closeAddingExpedModeDialog = useCallback(() => {
    setShowModalAddModeExped(false);
    setAddingError({ name: "" })
  }, [setShowModalAddModeExped]);

  const onSuccessAddingDialog = (event: React.FormEvent) => {
   event.preventDefault();
   if(!addingData.name){
    setAddingError({name:"Nom est obligatoire"})
  }
  else{

    setAddingError({ name: ""})
       addingCateg(addingData);
   
  }
  };

  const [addingData, setAddingData] = useState<IExpeditionCategoryAddingData>({
    name: "",

  });
  const [addingError, setAddingError] =
    useState<IExpeditionCategoryAddingDataError>({
      name: "",
    });
  
  const onChange = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if(!addingData.name){
          setAddingError({name:"Nom est obligatoire"})
        }
        else{
          setAddingError({name:""})

        }
        setAddingData({ ...addingData, [field]: e.target.value });
      
      },
    [addingData]
  );
  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    setIdCateg(data.data.id);
    displaySuccessSnackBar(t("delivery.successfully_adding"));
    closeAddingDialog()
    reset();
    setShowModalAddModeExped(true)
  }
  return (
    <React.Fragment>
    <Dialog
      open={openAddingDialog}
      handleClose={closeAddingDialog}
      title={t("delivery.add_cat")}
    >
      <form onSubmit={onSuccessAddingDialog}>
        <InputStyle>
          <TextInput
            value={addingData.name}
            label={t("tax.name")}
            onChange={onChange("name")}
            error={t(addingError.name)}
          />
        </InputStyle>

        <DeleteButtonsStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              title={t("common.cancel_button")}
              onClick={closeAddingDialog}
            />
          </ContainerButtonStyle>
          <ContainerButtonStyle>
            <Button
              type="submit"
              title={t("common.confirm_button")}
            />
          </ContainerButtonStyle>
        </DeleteButtonsStyle>
      </form>
    </Dialog>
     <Dialog
      open={showModalAddModeExped}
      handleClose={closeAddingExpedModeDialog}
      title={"Confirmation"}
    >
      <form onSubmit={()=>{navigate("/ExpeditionCategory/ExpeditionCategoryAdd",{state:{title:addingData.name,id:idCateg}})}}>
          <span>{t("delivery.msg")}</span>

        <DeleteButtonsStyle>
          <ContainerButtonStyle>
            <Button
              title={t("delivery.non")}
              onClick={closeAddingExpedModeDialog}
            />
          </ContainerButtonStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              type="submit"
              loader={isLoading}
              title={t("delivery.oui")}
            />
          </ContainerButtonStyle>
        </DeleteButtonsStyle>
      </form>
    </Dialog>
    </React.Fragment>
  );
}
export default AddExpeditionCategory;
