import { useTranslation } from "react-i18next";
import { ChangeEvent, useCallback, useState, useEffect } from "react";
import Dialog from "components/common/Dialog/Dialog";
import Button from "components/common/Button/Button";
import {
  ContainerButtonStyle,
  DeleteButtonsStyle,
  InputStyle,
} from "./AddFlashPromotion.style";
import SelectInputShow from "components/common/SelectInput/SelectInputShow";
import { CheckBox } from "@mui/icons-material";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { AutoInputsContainerStyle } from "features/Product/ProductAdd/Product.style";
import { DateTimePicker } from "@mui/x-date-pickers";
import {
  IPromotionFlashAddDialog,
  IPromotionFlashAddingData,
  IPromotionFlashesAddingDataError,
} from "./AddFlashPromotion.types";
import { useSnackBar } from "hooks/useSnackBar";
import TextInput from "components/common/TextInput/TextInput";
import FileInput from "components/common/FileInput/FileInput";
import { useAddTaxMutation } from "redux/api/tax/taxes";
import SelectInput from "components/common/SelectInput/SelectInput";
import useProductList from "features/Product/ProductList/useProductList";
import useCategoryList from "features/Category/CategoryList/useCategoryList";
import useSubCategoryList from "features/SubCategory/SubCategoryList/useSubCategoryList";
import { useAddPromotionFlashMutation } from "redux/api/promotionFlash/promotionFlash";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function AddFlashPromotion({
  openAddingDialog,
  setOpenAddingDialog,
}: IPromotionFlashAddDialog) {
  const { t } = useTranslation();

  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();
  const { productData } = useProductList(true);
  const [addingFlash, { data, error, isLoading, reset }] =
    useAddPromotionFlashMutation();

  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    displaySuccessSnackBar(t("promo_flash.successfully_adding"));
    reset();
  }
  const { categoryData } = useCategoryList();
  const { subCategoryData } = useSubCategoryList();
  const closeAddingDialog = useCallback(() => {
    setOpenAddingDialog(false);
    setAddingData({
      start_date: new Date(),
      end_date: new Date(),
      percentage_value: "",
      link: "",
      products: "",
      promotion_value: "",
      categories: "",
      subCategories: "",
      eur_value: "",
      value_type: "",
      subscriptions: "",
      eur_or_percentage: "",
      description: "",
      affectation: "",
    });
    setAddingError({
      start_date: "",
      end_date: "",
      percentage_value: "",
      link: "",
      products: "",
      categories: "",
      subCategories: "",
      eur_value: "",
      eur_or_percentage: "",
      description: "",
      subscriptions: "",
      affectation: "",
    });
  }, [setOpenAddingDialog]);
  const validate = () => {
    let ok = true;
    let addingPromotionError: IPromotionFlashesAddingDataError = {
      start_date: "",
      end_date: "",
      percentage_value: "",
      link: "",
      products: "",
      categories: "",
      subCategories: "",
      subscriptions: "",
      eur_value: "",
      eur_or_percentage: "",
      description: "",
      affectation: "",
    };
    if (addingData.description == "") {
      ok = false;
      addingPromotionError.description = "Ce champ est obligatoire";
    }
    if (addingData.link == "") {
      ok = false;
      addingPromotionError.link = "Ce champ est obligatoire";
    }
    if (addingData.eur_or_percentage == "") {
      ok = false;
      addingPromotionError.eur_or_percentage = "Ce champ est obligatoire";
    }
    if (addingData.eur_or_percentage == "euro" && addingData.eur_value == "") {
      ok = false;
      addingPromotionError.eur_value = "Ce champ est obligatoire";
    }
    if (
      addingData.eur_or_percentage == "percentage" &&
      addingData.percentage_value == ""
    ) {
      ok = false;
      addingPromotionError.percentage_value = "Ce champ est obligatoire";
    }
    if (addingData.start_date == null) {
      ok = false;
      addingPromotionError.start_date = "Ce champ est obligatoire";
    }
    if (addingData.end_date == null) {
      ok = false;
      addingPromotionError.end_date = "Ce champ est obligatoire";
    }
    if (addingData.link == "1" && addingData.categories.length == 0) {
      ok = false;
      addingPromotionError.categories = "Ce champ est obligatoire";
    }
    if (addingData.link == "2" && addingData.subCategories.length == 0) {
      ok = false;
      addingPromotionError.subCategories = "Ce champ est obligatoire";
    }
    if (addingData.link == "3" && addingData.products.length == 0) {
      ok = false;
      addingPromotionError.products = "Ce champ est obligatoire";
    }
    if (
      Number(addingData.percentage_value) < 0 ||
      Number(addingData.percentage_value) > 100
    ) {
      ok = false;
      addingPromotionError.percentage_value = "Pourcentage entre 0 et 100";
    }
    if (addingData.end_date <= addingData.start_date) {
      ok = false;
      addingPromotionError.end_date =
        "La date de fin doit être postérieure à la date de début";
    }
    console.log("addingPromotionError", addingPromotionError);
    setAddingError(addingPromotionError);
    return ok;
  };
  const onSuccessAddingDialog = (event: React.FormEvent) => {
    event.preventDefault();
    if (validate()) {
      addingFlash({
        ...addingData,
        start_date: addingData.start_date.valueOf(),
        end_date: addingData.end_date.valueOf(),
        value_type: addingData.eur_or_percentage,
        promotion_value: addingData.percentage_value
          ? addingData.percentage_value
          : addingData.eur_value,
        link:
          addingData.link === "1"
            ? "categories"
            : addingData.link === "2"
            ? "subcategories"
            : addingData.link === "3"
            ? "products"
            : addingData.link,
      });
      closeAddingDialog();
    }
  };
  useEffect(() => {
    setAddingData({
      ...addingData,
      start_date: new Date(),
      end_date: new Date(),
    });
  }, []);

  const [addingData, setAddingData] = useState<IPromotionFlashAddingData>({
    start_date: "",
    promotion_value: "",
    end_date: "",
    value_type: "",
    percentage_value: "",
    subscriptions: "",
    link: "",
    products: "",
    categories: "",
    subCategories: "",
    eur_value: "",
    eur_or_percentage: "",
    description: "",
    affectation: "",
  });
  const [addingError, setAddingError] =
    useState<IPromotionFlashesAddingDataError>({
      start_date: "",
      end_date: "",
      percentage_value: "",
      link: "",
      products: "",
      categories: "",
      subCategories: "",
      subscriptions: "",
      eur_value: "",
      eur_or_percentage: "",
      description: "",
      affectation: "",
    });

  const onChange = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setAddingData({ ...addingData, [field]: e.target.value });
        setAddingError({ ...addingError, [field]: "" });
        if (field == "percentage") {
          if (Number(e.target.value) > 0 && Number(e.target.value) <= 100) {
            setAddingData({ ...addingData, percentage_value: e.target.value });
          } else {
            setAddingData({ ...addingData, percentage_value: "" });
          }
        }

        if (field == "description") {
          if (!e.target.value) {
            setAddingError({
              ...addingError,
              description: "Description obligatoire",
            });
          } else {
            setAddingError({ ...addingError, description: "" });
          }
        }
      },
    [addingData]
  );

  const onChangeSelectLink = (e: string) => {
    setAddingData({ ...addingData, link: e.toString() });
    console.log("addingData", addingData);
    setAddingError({ ...addingError, link: "" });
  };
  const onChangeSelectAffectation = useCallback(
    (field: string) => (e: string) => {
      setAddingData({ ...addingData, [field]: e });
      setAddingError({ ...addingError, [field]: "" });
    },
    [addingData, addingError]
  );
  const onChangeSelectProductList = (e: string) => {
    setAddingData({ ...addingData, products: e });

    setAddingError({ ...addingError, products: "" });
  };
  const onChangeSelectCategory = (e: string) => {
    setAddingData({ ...addingData, categories: e });
    setAddingError({ ...addingError, categories: "" });
  };

  const onChangeSelectSubCategory = (e: string) => {
    setAddingData({ ...addingData, subCategories: e });

    setAddingError({ ...addingError, subCategories: "" });
  };

  return (
    <Dialog
      open={openAddingDialog}
      handleClose={closeAddingDialog}
      title={t("promo_flash.add")}
    >
      <form onSubmit={onSuccessAddingDialog}>
        <AutoInputsContainerStyle item xs={12} md={12} lg={12}>
          <SelectInput
            label={"affectation"}
            error={addingError.affectation}
            value={addingData.affectation}
            onChange={onChangeSelectAffectation("affectation")}
            data={[
              { id: 0, title: "Lolo Drive" },
              { id: 1, title: "KD Marche" },
              { id: 2, title: "Lolo Drive & KD Marche" },
            ].map((e: any) => ({
              id: e.id,
              name: e.title,
            }))}
          />
        </AutoInputsContainerStyle>
        <InputStyle>
          <TextInput
            value={addingData.description}
            label={t("promo_flash.description")}
            onChange={onChange("description")}
            error={t(addingError.description)}
          />
        </InputStyle>
        <AutoInputsContainerStyle item xs={12} md={12} lg={12}>
          <SelectInput
            label={"liaison"}
            error={addingError.link}
            value={addingData.link}
            onChange={onChangeSelectLink}
            data={[
              { id: 1, name: "Categories" },
              { id: 2, name: "Sous Catégories" },
              { id: 3, name: "Produit" },
            ]}
          />
          {addingData.link == "1" && (
            <SelectInputShow
              label={"Categorie"}
              error={addingError.categories}
              value={addingData.categories}
              onChange={onChangeSelectCategory}
              data={categoryData.data.map((category: any) => ({
                id: category.id,
                name: category.title,
              }))}
            />
          )}
          {addingData.link == "2" && (
            <SelectInputShow
              label={"Sous Catégories"}
              error={addingError.subCategories}
              value={addingData.subCategories ?? ""}
              onChange={onChangeSelectSubCategory}
              data={subCategoryData.data.map((subCategory: any) => ({
                id: subCategory.id,
                name: subCategory.title,
              }))}
            />
          )}
          {addingData.link == "3" && (
            <SelectInputShow
              label={"Produit"}
              error={addingError.products}
              value={addingData.products ?? ""}
              onChange={onChangeSelectProductList}
              data={productData.data.map((product: any) => ({
                id: product.id,
                name: product.title,
              }))}
            />
          )}
        </AutoInputsContainerStyle>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={(e) => {
            setAddingData({ ...addingData, eur_or_percentage: e.target.value });
            setAddingError({ ...addingError, eur_or_percentage: "" });
          }}
          style={{ display: "100%" }}
        >
          <div style={{ display: "inline-flex", width: "100%" }}>
            <FormControlLabel value={"euro"} control={<Radio />} label="euro" />
            <FormControlLabel
              value={"percentage"}
              control={<Radio />}
              label="pourcentage"
            />
          </div>
        </RadioGroup>
        {addingError.eur_or_percentage && (
          <p style={{ color: "red" }}>{addingError.eur_or_percentage}</p>
        )}
        {addingData.eur_or_percentage == "euro" && (
          <TextInput
            value={addingData.eur_value}
            type="number"
            label={t("promo_flash.euro")}
            onChange={onChange("eur_value")}
            error={t(addingError.eur_value)}
          />
        )}
        {addingData.eur_or_percentage == "percentage" && (
          <TextInput
            value={addingData.percentage_value}
            type="number"
            min={0}
            max={100}
            label={t("promo_flash.percentage")}
            onChange={onChange("percentage_value")}
            error={t(addingError.percentage_value)}
          />
        )}
        <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
          <InputStyle>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label={t("promotion_codes.start_date_title")}
                value={dayjs(addingData.start_date)}
                minDateTime={dayjs(
                  new Date(new Date().setDate(new Date().getDate() - 1))
                )}
                defaultValue={dayjs(new Date())}
                onChange={(start: any) => {
                  console.log("start", new Date(start));
                  setAddingData({ ...addingData, start_date: new Date(start) });
                  setAddingError({ ...addingError, start_date: "" });
                }}
              />
              <p style={{ color: "red" }}>{addingError.start_date}</p>
            </LocalizationProvider>
          </InputStyle>

          <InputStyle>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label={t("promotion_codes.end_date_title")}
                value={dayjs(addingData.end_date)}
                minDateTime={dayjs(addingData.start_date).add(1, "minute")}
                // defaultValue={ dayjs(new Date(new Date().setDate(new Date().getDate() + 1)))}

                onChange={(end: any) => {
                  console.log("end", end);
                  setAddingData({ ...addingData, end_date: new Date(end) });
                  setAddingError({ ...addingError, end_date: "" });
                }}
              />
              <p style={{ color: "red" }}>{addingError.end_date}</p>
            </LocalizationProvider>
          </InputStyle>
        </AutoInputsContainerStyle>
        <p>
          <b>NB:</b> Chaque produit ne peut être associé qu'à une seule
          promotion flash. L'ajout d'une nouvelle promotion flash pour un
          produit déjà lié entraînera automatiquement la désactivation de la
          promotion existante. De même, si vous ajoutez une promotion flash pour
          un produit qui appartient à une catégorie et qu'il existe déjà une
          promotion flash pour cette catégorie, la promotion existante sera
          automatiquement désactivée.
        </p>

        <DeleteButtonsStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              title={t("common.cancel_button")}
              onClick={closeAddingDialog}
            />
          </ContainerButtonStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              type="submit"
              loader={isLoading}
              title={t("common.confirm_button")}
            />
          </ContainerButtonStyle>
        </DeleteButtonsStyle>
      </form>
    </Dialog>
  );
}
export default AddFlashPromotion;
