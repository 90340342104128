import useCategoryList from "features/Category/CategoryList/useCategoryList";
import { SelectChangeEvent } from "@mui/material";
import { GlobalVariables } from "config/constant";
import { useSnackBar } from "hooks/useSnackBar";
import { ChangeEvent, useState } from "react";
import { useGetAllProductQuery } from "redux/api/product/product";
import { useGetAllSubCategoriesQuery } from "redux/api/category/category";
import { IProductData } from "./Product.types";

export default function useProductList(all: boolean) {
  const { categoryData } = useCategoryList();
  const { displayErrorApiSnackBar } = useSnackBar();
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [category, setCategory] = useState<any>("");
  const [selectedPOS, setSelectedPOS] = useState<any>("");
  const [subCategory_id, setSubCategory_id] = useState<any>("");
  const [filter, setFilter] = useState("all");
  const [status, setStatus] = useState<number | null>(null);
  const [perPage, setPerPage] = useState<number>(
    GlobalVariables.DefaultPerPage
  );
  const [orderBy, setOrderBy] = useState<string>("created_at");
  const [orderDir, setOrderDir] = useState<"" | "asc" | "desc">("desc");
  const [garantie, setGarantie] = useState<any>("");
  const [date_limite_de_consommation, setDate_limite_de_consommation] =
    useState<any>(null);

  const { data: subcategories, isLoading: isload } =
    useGetAllSubCategoriesQuery({
      id: category,
      all: true,
    });
  const {
    data: productData,
    error,
    isLoading,
    refetch,
  } = useGetAllProductQuery(
    all
      ? {
          paginator: {
            search: search,
            page: page,
            perPage: perPage,
            orderBy: orderBy,
            orderDir: orderDir,
          },
          all: true,
          category: category,
          Subcategory: subCategory_id,
          Stock: filter,
          Garantie: garantie,
          DLC: date_limite_de_consommation,
          Status: status,
          POS: selectedPOS,
        }
      : {
          paginator: {
            search: search,
            page: page,
            perPage: perPage,
            orderBy: orderBy,
            orderDir: orderDir,
          },
          category: category,
          Subcategory: subCategory_id,
          Stock: filter,
          Garantie: garantie,
          DLC: date_limite_de_consommation,
          Status: status,
          POS: selectedPOS,
        }
  );

  if (error) {
    displayErrorApiSnackBar(error);
  }

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onSearchChange = (e: ChangeEvent<HTMLInputElement> | string) => {
    setPage(1);
    if (typeof e === "string") {
      setSearch(e);
    } else {
      setSearch(e.target.value);
    }
  };
  const changeFilter = (e: any) => {
    setFilter(e);
    setPage(1);
  };
  const onChangeSelectCategory = (selectedCategory: string) => {
    if (selectedCategory === "all") {
      setCategory(""); 
      setSubCategory_id(""); 
    } else {
      setCategory(selectedCategory);
      setSubCategory_id(""); 
    }
  
    setPage(1); 
  };
  

  const handleSelectChange = (selectedValue: string) => {
    console.log(selectedValue);
    setSelectedPOS(selectedValue);
  };
  const onChangeSelectSubCategory = (selectedValue: string) => {
    if (selectedValue === "all") {
      setSubCategory_id("");  
    } else {
      setSubCategory_id(selectedValue);
    }
  };
  const onPerPageChange = (event: SelectChangeEvent<number>) => {
    setPerPage(parseInt(String(event.target.value), 10));
    setPage(1);
  };
  const changeGarantieFilter = (e: any) => {
    if (e === "none") {
      setGarantie("");
    } else {
      setGarantie(e);
    }
    setPage(1);
  };
  const changeDateLimiteConsFilter = (filter: string) => {
    if (filter === "all") {
      setDate_limite_de_consommation(null); 
    } else {
      const today = new Date();
      const hasExpiredDLC = productData?.data.some((e: IProductData) => {
        const dlcDate = new Date(e.date_limite_de_consommation);
        return dlcDate <= today;
      });
      setDate_limite_de_consommation(hasExpiredDLC ? true : false);
    }
    setPage(1); 
  };
  

  const [selectedStatus, setSelectedStatus] = useState("");

  const handleStatusSelectChange = (selectedStatus: any) => {
    setSelectedStatus(selectedStatus);

    switch (selectedStatus) {
      case 1:
        changeFilter("all");
        break;
      case 2:
        changeFilter("inStock");
        break;
      case 3:
        changeFilter("outOfStock");
        break;
      default:
        changeFilter("all");
    }
  };

  const changeStatus = (newStatus: number | "all") => {
    if (newStatus === "all") {
      setStatus(null);
    } else {
      setStatus(newStatus);
    }
    setPage(1);
  };
  const onOrderChange = (field: string) => () => {
    const order_dir =
      orderBy === field
        ? orderDir === "asc"
          ? "desc"
          : orderDir === "desc"
          ? ""
          : "asc"
        : "asc";
    setOrderDir(order_dir);
    setOrderBy(order_dir === "" ? "" : field);
  };

  const resetAllFilters = () => {
    setCategory("");
    setSubCategory_id("");
    setFilter("");
    setGarantie("");
    setDate_limite_de_consommation(null);
    setSelectedStatus("");
    setSelectedPOS("");
    setPage(1);
    setStatus(null);
  };

  return {
    onChangeSelectCategory,
    handleSelectChange,
    onSearchChange,
    onPageChange,
    onPerPageChange,
    onOrderChange,
    productData,
    isLoading,
    search,
    orderBy,
    orderDir,
    page,
    perPage,
    categoryData,
    category,
    selectedPOS,
    subcategories,
    subCategory_id,
    onChangeSelectSubCategory,
    changeFilter,
    filter,
    changeGarantieFilter,
    garantie,
    changeDateLimiteConsFilter,
    date_limite_de_consommation,
    changeStatus,
    status,
    resetAllFilters,
    selectedStatus,
    handleStatusSelectChange,
  };
}
